import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CaseDataService} from '../../services/case-data.service';
import {IncidentCauseCodeEnum} from '../../data/incidentCauseCodeEnum';
import {UntypedFormControl, Validators} from '@angular/forms';
import {FlowTypeEnum} from '../../data/flowTypeEnum';
import {FooterService} from 'sos-common-ui';
import {ProfileResourceService} from '../../shared/api/services/profile-resource.service';
import {EnvService} from '../../services/env.service';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import { InsuranceCompany } from 'src/app/data/insuranceCompany';
import { CasedataModel } from 'src/app/data/casedata.model';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html'
})
export class OptionComponent implements OnInit, OnDestroy {
  cause = IncidentCauseCodeEnum;
  // default without ONLINE_CARE
  incidentCauses = Object.values(IncidentCauseCodeEnum).filter(item => item !== IncidentCauseCodeEnum.ONLINE_CARE);
  incidentCause = new UntypedFormControl(null, [Validators.required]);
  dataTestId = 'what-can-we-help-you-with';
  private fetchOnlinecareincludedProfilecode?: Subscription;

  constructor(
    private router: Router,
    private caseDataService: CaseDataService,
    private footerService: FooterService,
    private profileResourceService: ProfileResourceService,
    private envService: EnvService) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/option page');
    this.footerService.setNavigationMode({
      enableNavForward: this.enableForward.bind(this), navLinkForward: this.getLink.bind(this),
      onNextFunction: this.save.bind(this)
    });

    const profileCode: number = this.extractInsuranceCompany(this.caseDataService.getCasedata()).profileId;
    const insuranceCode: string = this.extractInsuranceCompany(this.caseDataService.getCasedata()).profileCode;
    this.insuranceCompanyOptionsInOptionFlow(profileCode, insuranceCode);
  }

  // Filtering the insurance company to determine what should be displayed in the option flow
  insuranceCompanyOptionsInOptionFlow(profilecode: number, insuranceCode: string ): void {
    this.fetchOnlinecareincludedProfilecode =
      this.profileResourceService.v1OnlinecareincludedProfilecodeGet({profilecode, application: EnvService.PROP_APP_NAME})
        .pipe(map(val => val.onlineCareIncluded))
        .subscribe({
          next: val => {
            if (val && val === true) {
              this.incidentCauses = Object.values(IncidentCauseCodeEnum);
            }
            if (this.envService.blackListProfileCodeLuggage.includes(insuranceCode)) {
              this.incidentCauses = this.incidentCauses.filter((x) =>
                x !== IncidentCauseCodeEnum.LUGGAGE);
            }
            if (this.envService.blackListProfileCodeOther.includes(insuranceCode)) {
              this.incidentCauses = this.incidentCauses.filter((x) =>
                x !== IncidentCauseCodeEnum.OTHER);
            }
            if (this.incidentCauses.some(x => x === this.caseDataService.getCasedata().incidentCauseCode)) {
              this.incidentCause.setValue(this.caseDataService.getCasedata().incidentCauseCode);
            }
          },
          error: err => console.error(err)
        });
  }

  extractInsuranceCompany(caseData: CasedataModel): InsuranceCompany {
    return caseData?.insuranceCompany ?? caseData?.creditCardCompany;
  }

  save(): void {
    this.caseDataService.update(caseData => {
      caseData.incidentCauseCode = this.incidentCause.value;
      caseData.flowType = caseData.incidentCauseCode === this.cause.ONLINE_CARE ? FlowTypeEnum.ONLINE_CARE : FlowTypeEnum.CREATE_CASE;
    });
  }

  enableForward(): boolean {
    return this.incidentCause.valid;
  }

  getLink(): string {
    return [this.cause.MEDICAL, this.cause.ONLINE_CARE].includes(this.incidentCause.value) ? '/inquiry-select' : '/case-description';
  }

  ngOnDestroy(): void {
    this.fetchOnlinecareincludedProfilecode?.unsubscribe();
  }
}
