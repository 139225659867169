import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CaseDataService } from "../../services/case-data.service";
import { filterLanguageToNordic, FooterService, LanguageService, SelectFlagOption } from "sos-common-ui";
import { StartComponent } from "../start/start.component";
import {CustomerProductTypeEnum} from '../../data/customerProductTypeEnum';
import { InsuranceCompany } from "../../data/insuranceCompany";
import {CasecreateService} from '../../services/casecreate.service';
import { map } from "rxjs/operators";
import { InsuranceCountry } from "../../data/insuranceCountry";
import { CasedataModel } from "../../data/casedata.model";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import {LoggingService} from "frontend-logging-travelcare";
import {MDC} from "../../data/mdc";

@Component({
  selector: 'app-insurance-select',
  templateUrl: './insurance-select.component.html'
})
export class InsuranceSelectComponent implements OnInit {
  private static SELECTED_COUNTRY_SESSION_KEY = 'sesKeySelectedInsuranceCountry';
  dataTestId = 'how-are-you-insured';
  insuranceCompany!: InsuranceCompany;
  insuranceCompanyList: InsuranceCompany[] = [];
  customerProductTypes: CustomerProductTypeEnum[] = [];
  countries: SelectFlagOption[] = InsuranceCountry.COUNTRIES.map(l => ({
    flagAlpha2Code: l.flagCode,
    text: l.name,
    code: l.countryCode
  } as SelectFlagOption));

  form: FormGroup = new FormGroup({
    customerProductType: new FormControl<CustomerProductTypeEnum | null>(null),
    nationality: new FormControl('', [Validators.required]),
    insuranceCompany: new FormControl('', [Validators.required])
  });

  constructor(private router: Router,
              private casedataService: CaseDataService,
              private footerService: FooterService,
              private casecreateService: CasecreateService,
              private languageService: LanguageService,
              private translate: TranslateService,
              private loggingService: LoggingService)
  {}
  ngOnInit(): void {
    console.info('/insurance-select page');
    this.customerProductTypes = [CustomerProductTypeEnum.TRAVEL_INSURANCE, CustomerProductTypeEnum.CREDIT_CARD];

    const dataSetViaUrl: string = this.casedataService.getSessionData(StartComponent.INSURANCE_SET_VIA_URL);
    if (dataSetViaUrl === 'true') {
      this.router.navigate(['/insurance-in-my-name']);
    }

    this.setRadioButtonData();
    const customerProductTypeControl = this.form.get('customerProductType');
    if (customerProductTypeControl) {
      customerProductTypeControl.valueChanges.subscribe(value => {
        this.setInsuranceCompany(true);
      });
    }

    let currentCountry = this.casedataService.getSessionData(InsuranceSelectComponent.SELECTED_COUNTRY_SESSION_KEY);
    if (!currentCountry) {
      currentCountry =  this.languageService.getCountryCode(filterLanguageToNordic(this.translate.getDefaultLang()));
    }
    this.form.controls.nationality.setValue(currentCountry);

    const caseDataModel: CasedataModel = this.casedataService.getCasedata();
    if (caseDataModel.insuranceCompany) {
      this.form.controls.insuranceCompany.setValue(caseDataModel.insuranceCompany);
      this.setInsuranceCompany();
    } else if(caseDataModel.creditCardCompany) {
      this.form.controls.insuranceCompany.setValue(caseDataModel.creditCardCompany);
      this.setInsuranceCompany();
    }else{
      this.setInsuranceCompany(true);
    }
    this.footerService.setNavigationMode({enableNavForward: this.enableForward.bind(this), navLinkForward: this.getLink.bind(this)});
  }

  setRadioButtonData(): void {
    const caseData = this.casedataService.getCasedata();
    if (caseData.creditCard === true) {
      this.form.controls.customerProductType.setValue(CustomerProductTypeEnum.CREDIT_CARD);
    } else if (caseData.creditCard === false) {
      this.form.controls.customerProductType.setValue(CustomerProductTypeEnum.TRAVEL_INSURANCE);
    }
  }
  onNationalityChange(): void {
    this.casedataService.saveSessionData(InsuranceSelectComponent.SELECTED_COUNTRY_SESSION_KEY, this.form.controls.nationality.value);
    this.setInsuranceCompany(true);
  }

  onInsuranceCompanyChange(): void {
    this.casedataService.update( caseData => {
      caseData.creditCard = this.form.controls.customerProductType.value === CustomerProductTypeEnum.CREDIT_CARD;
      if(caseData.creditCard) {
        caseData.creditCardCompany = this.form.controls.insuranceCompany.value;
        caseData.insuranceCompany = null;
        this.loggingService.addMDC(MDC.CPID, caseData.creditCardCompany.profileId + '');
        this.loggingService.addMDC(MDC.CPCode, caseData.creditCardCompany.profileCode);
        this.loggingService.addMDC(MDC.CPName, caseData.creditCardCompany.customerProfileName);
      } else {
        caseData.insuranceCompany = this.form.controls.insuranceCompany.value;
        caseData.creditCardCompany = null;
        this.loggingService.addMDC(MDC.CPID, caseData.insuranceCompany.profileId + '');
        this.loggingService.addMDC(MDC.CPCode, caseData.insuranceCompany.profileCode);
        this.loggingService.addMDC(MDC.CPName, caseData.insuranceCompany.customerProfileName);
      }
    });
  }

  public isCreditCard(): boolean {
    return this.casedataService.getCasedata().creditCard;
  }
  customerProductTypeSelected(): boolean {
    return this.form.controls.customerProductType.value !== null;
  }
  setInsuranceCompany(resetInsuranceCompany?: boolean): void {
    if (!this.form.controls.nationality.value) {
      this.form.controls.insuranceCompany.setValue(null);
    }

    const lang = this.form.controls.nationality.value.toLowerCase();
    this.fetchInsuranceCompanies(lang).subscribe({
      next: (values) => {
        this.insuranceCompanyList = values;
        if (resetInsuranceCompany) {
          this.form.controls.insuranceCompany.setValue(null);
        }
      },
      error: err => console.error(err)
    });
  }

  fetchInsuranceCompanies(lang: string): Observable<InsuranceCompany[]> {
    return this.casecreateService.getInsuranceCompanies(lang).pipe(
      map(data => data.filter(a => a.customerProductType === this.form.controls.customerProductType.value)),
      map(filteredData => this.sortList(filteredData))
    );
  }
  sortList(list: InsuranceCompany[]): InsuranceCompany[] {
    return list.sort((x, y) => x.customerProfileName!.replace('(', '').localeCompare(y.customerProfileName!.replace('(', '')));
  }

  getInsurancePlaceholder() {
    const productType =  this.form.controls.customerProductType.value ;
    return 'insurance-select.placeholder-' + productType;
  }

  enableForward(): boolean {
    return this.form.valid;
  }

  public getLink(): string {
    return '/insurance-in-my-name';
  }
}
